import axios from "axios";
// import SSH from "@/assets/js/sessionStorageHandler";
// axios.defaults.timeout = 20000;
axios.defaults.headers.post["Content-Type"] = "application/json;charset=UTF-8";
// axios.defaults.baseURL = 'https://www.qet.com';
// import Vue from "vue";
import router from "@/router";
// import { Toast } from "vant";
// Vue.use(Toast);
import { Message } from "element-ui";
import NProgress from "nprogress";
import "nprogress/nprogress.css";

// 发送请求时的拦截操作
axios.interceptors.request.use(
  (config) => {
    // config.headers.requester = "app";
    // // 如果token存在,则在请求头添加token参数
    // if (SSH.getItem("token")) {
    //   config.headers.token = `${SSH.getItem("token")}`;
    // }
    // NProgress.start();
    return config;
  },
  (err) => {
    // console.log('拦截',err);
    return Promise.reject(err);
  }
);

// 路由拦截
axios.interceptors.response.use(
  (res) => {
    console.log(res);
    NProgress.done();
    // 判断当前请求数据是否正确
    var data = res.data;
    if (data.code === 1000) {
      return Promise.resolve(data);
    } else if (data.code == 401) {
      // Toast.fail("登录失效，请重新登录！");
      Message({
        message:'登录失效，请重新登录！',
        type: 'error',
        duration:1500,
      })
      sessionStorage.clear(); // 清除所有session值
      router.push("login");
    } else {
      // Toast.fail(data.message || data.msg);
      Message({
        message:data.message || data.msg,
        type: 'error',
        duration:1500,
      })
      return Promise.reject(data);
    }
  },
  (err) => {
    NProgress.done();
    // var data = err.response;
    // if (data.status == 401) {
    //   Toast.fail("登录失效，请重新登录！");
    //   sessionStorage.clear(); // 清除所有session值
    //   router.push("login");
    // } else {
    //   Toast.fail("连接服务器失败,请检查网络是否正常");
    // }
    return Promise.reject(err);
  }
);
export default axios;
