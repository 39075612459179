// const prefix = '';

export default {
    common: {
      getNews: "/api/content/index",// 获取新闻列表，岗位信息
      getNewsDetail: "/api/content",// 获取新闻详情
      set: "/api/template/setting",// 获取设置
      joinIn: "/api/tpl/leaveMessage/joinIn",// 提交信息
      dictionary: "/api/template/dictionary",// 字典
      categoryExtra: "/api/template/categoryExtra",// 获取子分类信息
    }
}