import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/index'
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/register.vue')
  },
  {
    path: '/loading',
    name: 'Loading',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/loading')
  },
  {
    path: '/leftmenu',
    name: 'leftmenu',
    component: () => import('../components/leftMenu'),
    children: [
      {
        path: '/index',
        name: 'Index',
        component: () => import('../views/index')
      },
      {
        path: '/groupIntro',
        name: 'groupIntro',
        component: () => import('../views/groupIntro')
      },
      {
        path: '/productDetail',
        name: 'productDetail',
        component: () => import('../views/productDetail')
      },
      {
        path: '/product',
        name: 'product',
        component: () => import('../views/product')
      },
      {
        path: '/contactus',
        name: 'contactUs',
        component: () => import('../views/contactUs')
      },
      {
        path: '/joinus',
        name: 'joinUs',
        component: () => import('../views/joinUs')
      },
      {
        path: '/socialNews',
        name: 'socialNews',
        component: () => import('../views/socialNews')
      },
      {
        path: '/companyNews',
        name: 'companyNews',
        component: () => import('../views/companyNews')
      },
      {
        path: '/joinBrand',
        name: 'joinBrand',
        component: () => import('../views/joinBrand')
      },
      {
        path: '/innovation',
        name: 'innovation',
        component: () => import('../views/innovation')
      },
      {
        path: '/paulFrank',
        name: 'paulFrank',
        component: () => import('../views/paulFrank')
      },
      {
        path: '/bOBDOG',
        name: 'bOBDOG',
        component: () => import('../views/bOBDOG')
      },
      {
        path: '/boboDog',
        name: 'boboDog',
        component: () => import('../views/boboDog')
      },
      {
        path: '/groupHistroy',
        name: 'groupHistroy',
        component: () => import('../views/history')
      },
      {
        path: '/detail',
        name: 'detail',
        component: () => import('../views/detail')
      },
      {
        path: '/intro',
        name: 'Intro',
        component: () => import('../views/intro')
      },
      {
        path: '/intro1',
        name: 'Intro1',
        component: () => import('../views/intro/intro_1')
      },
      {
        path: '/intro2',
        name: 'Intro2',
        component: () => import('../views/intro/intro_2')
      },
    ]
  },
  {
    path: '*',
    redirect: '/index'
  },
]

const router = new VueRouter({
  routes,
  scrollBehavior (to, from, savedPosition) {
    // return 期望滚动到哪个的位置
    if (savedPosition) {
      return savedPosition
    } else if(to.hash){
      return {
        selector: to.hash
      }
    } else {
      return { x: 0, y: 0 }
    }
    
  }
})

export default router
