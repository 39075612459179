import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import http from "@/api/http.js";
import apiUrl from "@/api/apiUrl/index";

Vue.prototype.$http = http;
Vue.prototype.$apiUrl = apiUrl;

Vue.config.productionTip = false
Vue.use(ElementUI)

http.get(apiUrl.common.set,{}).then((res) => {
  Vue.prototype.$cndHost = res.config.cdn_host;
  Vue.prototype.$settingList = res.settingList
  new Vue({
    router,
    render: h => h(App)
  }).$mount('#app')
})

